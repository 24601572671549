import React, { useMemo } from 'react'
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import DivWithWavedClipPathAnimation from "./DivWithWavedClipPathAnimation"
import useTheme from '@mui/system/useTheme';
import { configWavePathDNames } from "../../utils/calculateSvgPath"
import { maskPatternsHoriz } from "../../utils/staticPathsForMaskImages"
import Skeleton from '@mui/material/Skeleton';
import useMediaQuery from '@mui/material/useMediaQuery';

const namesOfPaths = Object.keys(configWavePathDNames).filter(key => !key.match(/Half$|\d+$/));
// console.log("namesOfPaths.length", namesOfPaths.length)
const maskPatterns = Object.keys(maskPatternsHoriz)
const numberOfMaskPatterns = maskPatterns.length
const itemData = [
    {
        title: 'Breakfast',
        rows: 2,
        cols: 2,
        clipPathId: "sinusWaveFromLeftToBow",
        nameOfPaths: "sinusWaveFromLeftToBow",
        otherProps: {
            // maskName: "mask3",
            styleDiv: {
                // backgroundColor: "green",
                minHeight: "unset", height: "inherit",
            },
        },
    },
    {
        title: 'Breakfast',
        clipPathId: "sinusWaveFromLeftToBow",
        nameOfPaths: "sinusWaveFromLeftToBow",
        otherProps: {
            // maskName: "mask3",
            styleDiv: { backgroundColor: "green", minHeight: "unset", height: "inherit", },
        },
    },
    {

        title: 'Breakfast',
        clipPathId: "sinusWaveFromLeftToBow",
        nameOfPaths: "sinusWaveFromLeftToBow",
        otherProps: {
            // maskName: "mask3",
            styleDiv: { backgroundColor: "red", minHeight: "unset", height: "inherit", },
        },
    },
    {
        title: 'Breakfast',
        clipPathId: "sinusWaveFromLeftToBow",
        nameOfPaths: "sinusWaveFromLeftToBow",
        otherProps: {
            // maskName: "mask3",
            styleDiv: { backgroundColor: "orange", minHeight: "unset", height: "inherit", },
        },
    },
    {
        title: 'Breakfast',
        rows: 2,
        cols: 2,
        clipPathId: "sinusWaveFromLeftToBow",
        nameOfPaths: "sinusWaveFromLeftToBow",
        otherProps: {
            // maskName: "mask8",
            styleDiv: { minHeight: "unset", height: "inherit", },
        },
    },
    {

        title: 'Breakfast',
        clipPathId: "circleAndCirclesAround",
        nameOfPaths: "circleAndCirclesAround",
        otherProps: {
            // maskName: "mask3",
            styleDiv: { minHeight: "unset", height: "inherit", },
        },
    },
    {
        title: 'Breakfast',
        cols: 2,
        clipPathId: "sinusWaveFromLeftToBow",
        nameOfPaths: "sinusWaveFromLeftToBow",
        otherProps: {
            // maskName: "mask11",
            styleDiv: { minHeight: "unset", height: "inherit", },
        },
    },
    {

        title: 'Breakfast',
        clipPathId: "circleAndCirclesAround",
        nameOfPaths: "circleAndCirclesAround",
        otherProps: {
            // maskName: "mask8",
            styleDiv: { minHeight: "unset", height: "inherit", },
        },
    },
    {
        title: 'Breakfast',
        rows: 2,
        cols: 2,
        clipPathId: "circleAndCirclesAround",
        nameOfPaths: "circleAndCirclesAround",
        otherProps: {
            // maskName: "mask8",
            styleDiv: { minHeight: "unset", height: "inherit", },
        },
    },
    {

        title: 'Breakfast',
        clipPathId: "circleAndCirclesAround",
        nameOfPaths: "circleAndCirclesAround",
        otherProps: {
            // maskName: "mask3",
            styleDiv: { minHeight: "unset", height: "inherit", },
        },
    },
    {
        title: 'Breakfast',
        cols: 2,
        clipPathId: "sinusWaveFromLeftToBow",
        nameOfPaths: "sinusWaveFromLeftToBow",
        otherProps: {
            // maskName: "mask11",
            styleDiv: { minHeight: "unset", height: "inherit", },
        },
    },
    {
        title: 'Breakfast',
        rows: 2,
        cols: 2,
        clipPathId: "circleAndCirclesAround",
        nameOfPaths: "circleAndCirclesAround",
        otherProps: {
            // maskName: "mask8",
            styleDiv: { minHeight: "unset", height: "inherit", },
        },
    },
    {
        title: 'Breakfast',
        clipPathId: "circleAndCirclesAround",
        nameOfPaths: "circleAndCirclesAround",
        otherProps: {
            // maskName: "mask8",
            styleDiv: { minHeight: "unset", height: "inherit", },
        },
    },
]
// console.log("itemData.length", itemData.length)

const DivsWithBgGradientAnimatedViaMuiImageList = ({ name = "ImageListOfDivsWithBgGradientAnimated" }) => {

    const theme = useTheme();
    const bgImageFromGradientColor1 = theme.palette.info.main
    const bgImageFromGradientColor2 = theme.palette.secondary.main
    const bgImageToGradientColor1 = theme.palette.secondary2.main
    const bgImageToGradientColor2 = theme.palette.warning.main
    const length = 12;


    // Use media query to adjust columns based on screen width
    const matchesXS = useMediaQuery(theme.breakpoints.down('xs'));
    const matchesSM = useMediaQuery(theme.breakpoints.between('sm', 'md'));
    const matchesMD = useMediaQuery(theme.breakpoints.between('md', 'lg'));
    const matchesLG = useMediaQuery(theme.breakpoints.up('lg'));


    let cols;
    const rowHeight = 70;
    if (matchesXS) {
        cols = 2;
    } else if (matchesSM) {
        cols = 3;
    } else if (matchesMD) {
        cols = 4;
    } else if (matchesLG) {
        cols = 5;
    }

    const items = useMemo(() => {
        return itemData.filter((v, i) => i < 10).map((item, i) => {
            const { title, cols, rows } = item
            const nameOfPaths = namesOfPaths[ i ]
            const clipPathId = `${name}-${nameOfPaths}-${i}`
            const maskName = maskPatterns[ i % numberOfMaskPatterns ]
            return (
                <ImageListItem key={`${clipPathId}-${i}`}
                    cols={cols || 1}
                    rows={rows || 1}
                    className="clay"
                    sx={{
                        boxShadow: theme.shadows[ 15 ],
                    }}>

                    <DivWithWavedClipPathAnimation
                        nameOfPaths={nameOfPaths}
                        clipPathId={clipPathId}
                        bgImageFromGradientColor1={bgImageFromGradientColor1}
                        bgImageFromGradientColor2={bgImageFromGradientColor2}
                        bgImageToGradientColor1={bgImageToGradientColor1}
                        bgImageToGradientColor2={bgImageToGradientColor2}
                        maskName={maskName}
                        isMaskPatternHorizontal={true}
                        {...item.otherProps}
                    />
                    <ImageListItemBar
                        className="clay"
                        title={<Skeleton
                            animation="wave"
                            height={10}
                            width="80%"
                            style={{ marginBottom: 6 }}
                        />}
                        subtitle={<Skeleton animation="wave" height={10} width="40%" />}
                        position="below"
                    />
                </ImageListItem>
            )
        });
    }
        , [ theme, bgImageFromGradientColor1, bgImageFromGradientColor2, bgImageToGradientColor1, bgImageToGradientColor2, maskPatterns, numberOfMaskPatterns, name, namesOfPaths, itemData ])

    return (
        <ImageList
            sx={{
                // width: 600,
                // width: 600,
                maxHeight: "300px",
                width: "100%",
                // overflowY: 'scroll'
                overflow: 'hidden',
                borderRadius: "50%",
            }}
            cols={cols}
            rowHeight={rowHeight} //{164}
            // variant="quilted"
            // variant="masonry"
            gap={8}
        >
            {items}
        </ImageList>
    )
}
export default DivsWithBgGradientAnimatedViaMuiImageList